import { A4000_DEFAULT_CONFIG } from '../utils/device-config';
import { UNITS } from '../utils/units.enum';
import { A4000_IRRIGATION_PROGRAM_STATUS } from '../utils/irrgation-status.enum';
import { getContainer } from '@vegga/front-store';
import { Subject, take, takeUntil } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('programsController', programsController)
    .controller('ModalInstanceCtrl', ModalInstanceCtrl);

  programsController.$inject = [
    '$log',
    '$rootScope',
    '$scope',
    '$state',
    'Restangular',
    '$mdDialog',
    'progFactory',
    'UserData',
    '$uibModal',
    '$confirm',
    '$filter',
    'veggaModalConfirmService',
  ];

  function programsController(
    $log,
    $rootScope,
    $scope,
    $state,
    Restangular,
    $mdDialog,
    progFactory,
    UserData,
    $uibModal,
    $confirm,
    $filter,
    veggaModalConfirmService
  ) {
    var vm = this;
    vm.destroy$ = new Subject();
    //Enumerat per definir els tipus de inici
    const startTypes = Object.freeze({
      hour: 0, //Inici per horari
      seq: 1, //Inici seqüencial
      cond: 2, //Inici per condicionant
    });
    //Enumerat per definir els tipus d'unitat de reg
    const unitTypes = Object.freeze({
      HM: 0, //hh:mm
      M3: 1, //m3
      M3HA: 2, // m3/Ha -> Sols al Plus
      MS: 3, // Minuts, Segons
      HMHA: 4, // Hores, Minuts /Ha v.6.00   -> Sols al Plus
    });

    vm.activeList = [];
    vm.filterList = [];
    vm.searchByProgramName = '';
    //vm.gridColumnsDef = [{'headerName': 'Programas', 'field': 'name'}, {'headerName': 'Inicio Riego', 'field': 'irrigationHour'}, {'headerName': 'Estado', 'field': '_state'}];

    vm.newProgram;

    vm.getProgramStartType;
    vm.getProgramDaysFreq;
    vm.getProgramValueInUnit;
    vm.changeProgramState;
    vm.UNITS = UNITS;
    vm.currentState = $state;
    vm.changeState = changeState;
    vm.newProgram = newProgram;
    vm.openUnoperative = openUnoperative;
    vm.dataGridRowClick = dataGridRowClick;
    vm.changeProgram = changeProgram;
    vm.changingProgram = changingProgram;
    vm.nextOrPreviousItem = nextOrPreviousItem;
    vm.getProgramStartType = getProgramStartType;
    vm.getProgramDaysFreq = getProgramDaysFreq;
    vm.getProgramStartTime = getProgramStartTime;
    vm.getProgramValueInUnit = getProgramValueInUnit;
    vm.cancel = cancel;
    vm.save = save;
    vm.changeProgramState = changeProgramState;
    vm.filterByName = filterByName;
    vm.updateFilter = updateFilter;
    vm.showSkeleton = true;

    vm.irrigationUnits = A4000_DEFAULT_CONFIG.measurementUnits.irrigation;

    vm.gridFilter = { name: vm.searchByProgramName };

    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    const grid = document.querySelector('vegga-data-grid');

    getColDef();
    initSubscriptions();
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$), take(1)).subscribe((currentUnit) => {
        if (!currentUnit) {
          return;
        }
        vm.currentUnit = currentUnit;
        loadData();
      });
    }

    function initSubscriptions() {
      vm.programsFacade.isLoading$.pipe(takeUntil(vm.destroy$)).subscribe((isLoading) => {
        if (!isLoading) {
          grid.showFullSkeleton = isLoading;
          grid.showRowSkeleton = isLoading;

          vm.showSkeleton = isLoading;
        }
      });
    }

    function loadData() {
      // vm.program = $state.params.program;

      loadPrograms(vm.currentUnit.id);
    }

    function getSubprogramsTemplate({ configuredSubprograms, xSubprogramCourse, xState }) {
      return configuredSubprograms.length
        ? `<div style="flex-direction: column; align-items: start">${configuredSubprograms.map(
            ({
              pk,
              value,
              sector1,
              sector2,
              sector3,
              sector4,
              sector5,
              sector6,
              sector7,
              sector8,
              sector9,
              sector10,
              unit,
            }) => {
              const configuredSectors = [
                sector1,
                sector2,
                sector3,
                sector4,
                sector5,
                sector6,
                sector7,
                sector8,
                sector9,
                sector10,
              ].filter((sector) => sector !== 0);

              const measurementUnit = vm.irrigationUnits.find((u) => u.deviceMeasurementUnitKey === unit);

              if (!measurementUnit) {
                return `<vegga-badge class=${xState && xSubprogramCourse + 1 === pk.id ? 'info' : ''} >SP${
                  pk.id
                } - ${configuredSectors.map((sector) => `S${sector} `)}</vegga-badge>`;
              }

              return `<vegga-badge class=${xState && xSubprogramCourse + 1 === pk.id ? 'info' : ''} >SP${
                pk.id
              } - (${parseMeasurementUnitValue(value, measurementUnit.deviceMeasurementUnitKey)} ${
                measurementUnit.measurementUnit.symbol
              }) - ${configuredSectors.map((sector) => `S${sector} `)}</vegga-badge>`;
            }
          )} </div>`.replaceAll(',', '')
        : ` `;
    }

    function getEditStartCellTemplate({ sequential, editStart }) {
      if (sequential) return `<vegga-text>${$filter('translate')('programs.edit.seq')}</vegga-text>`;
      return `<vegga-text>${editStart}</vegga-text>`;
    }

    function getStateTemplate({ configuredSubprograms, irrigation, substate }) {
      const badgeColor = A4000_IRRIGATION_PROGRAM_STATUS[substate];
      if (!configuredSubprograms.length || !substate) {
        return '';
      }
      if (!irrigation) {
        return `<vegga-badge class="${badgeColor}">${$filter('translate')(substate)}</vegga-badge>`;
      }
      return `
          <div style="display:'flex'; flex-direction: 'column'">
            <vegga-badge class="sm info mb-1">${$filter('translate')('programs.irrigating')}</vegga-badge>
            <vegga-badge class="${badgeColor}">${$filter('translate')(substate)}</vegga-badge>
          </div>
          `;
    }

    function getCellTemplate(value, field) {
      if (field === 'name') {
        return `<vegga-text class="semi-bold">${value.name}</vegga-text>`;
      }

      if (field === 'days') {
        // if (value.sequential) return `<vegga-text>${value.next}</vegga-text>`;
        if (value.sequential) return `<vegga-text>-</vegga-text>`;
        if (value.type === 1) {
          return `<vegga-text>${getProgramDaysFreq(value)}</vegga-text>`;
        }
        if (value.type === 2) {
          return `<vegga-text>${value.activations} ${$filter('translate')('programs.edit.acti')} (${$filter(
            'translate'
          )('programs.edit.oneach')} ${parseMeasurementUnitValue(value.activationsFreq, 0)}hh:mm)</vegga-text>`;
        }

        if (value.type === 0) {
          const DAYS = {
            1: 'monday',
            2: 'tuesday',
            3: 'wednesday',
            4: 'thursday',
            5: 'friday',
            6: 'saturday',
            7: 'sunday',
          };

          const parsedDays = Object.keys(DAYS)
            .filter((dayKey) => value[DAYS[dayKey]])
            .map((dayKey) => +dayKey);
          return `<vegga-time-display days="[${parsedDays}]"></vegga-time-display>`;
        }

        return `<vegga-text>-</vegga-text>`;
      }
    }

    function getIrrigationStartCellTemplate(value) {
      if (value.sequential) {
        return `<vegga-text>${value.next.split(' ')[1]}</vegga-text>`;
      } else {
        return `<vegga-text>${parseMeasurementUnitValue(value.start, 0)}hh:mm</vegga-text>`;
      }
    }

    function parseMeasurementUnitValue(value, deviceMeasurementUnitKey) {
      switch (deviceMeasurementUnitKey) {
        case 0: {
          const m = value % 60;
          const h = (value - m) / 60;
          return (h < 10 ? '0' : '') + h.toString() + ':' + (m < 10 ? '0' : '') + m.toString();
        }
        case 1: {
          const seconds = value % 60;
          const minutes = (value - seconds) / 60;
          return (minutes < 10 ? '0' : '') + minutes.toString() + ':' + (seconds < 10 ? '0' : '') + seconds.toString();
        }
        default:
          return value;
      }
    }

    function dataGridRowClick({ detail }) {
      vm.programSelected = vm.programsList.find((listElement) => listElement.pk.id === detail.pkId);

      changeState(
        { program: vm.programSelected, id: vm.programSelected.pk.id, unit: vm.currentUnit },
        $state.params.config
      );
    }

    function updateFilter(param, field) {
      vm.gridFilter = {
        ...vm.gridFilter,
        [field]: param,
      };
    }

    function changeProgram(e) {
      if (e.detail.value) {
        vm.programSelected = e.detail.value;
        changeState(
          { program: vm.programSelected, id: vm.programSelected.pk.id, unit: vm.currentUnit },
          $state.params.config
        );
      }
    }

    function changingProgram($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;

        const modalHeader = vm.currentState.includes('programs.edit')
          ? $filter('translate')('programs.prog_edit')
          : $filter('translate')('programs.prog_config');

        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: modalHeader,
            content: $filter('translate')('programs.edit.cancelq'),
            cancelText: $filter('translate')('AUTOGENERATED.ACTIONS.ACT0003_CANCEL'),
            confirmText: $filter('translate')('units.not_migrated.actions.accept'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
              vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
              this.nextOrPreviousItem($event.detail.action, $event);
            }
          });
      }
    }

    function nextOrPreviousItem(action, $event) {
      const paginator = document.querySelector('vegga-item-paginator');
      switch (action) {
        case 'next-item':
          paginator.nextItem();
          break;
        case 'previous-item':
          paginator.previousItem();
          break;
        default:
          changeProgram($event);
          break;
      }
    }

    function openUnoperative(id) {
      vm.active = id;
      progFactory.program(vm.currentUnit.id, id).then((result) => {
        result.op = false;
        vm.program = result;
        if ($state.includes('programs.config')) {
          $state.go('programs.config', { program: result, id: result.pk.id, unit: vm.currentUnit });
        } else {
          $state.go('programs.edit', { program: result, id: result.pk.id, unit: vm.currentUnit });
        }
      });
    }
    function loadPrograms(id) {
      vm.programsFacade.getA4000Programs(id);
      vm.programsFacade.programs$.pipe(takeUntil(vm.destroy$)).subscribe((data) => {
        vm.programsList = progFactory.filterActivePrograms4000(data, vm.currentUnit.type);

        grid.data = vm.programsList
          .map((i) => ({
            pkId: i.pk.id,
            name: i.name,
            editStart: $filter('translate')(getProgramStartType(i.type)),
            days: [],
            startTime: getProgramStartTime(i),
            state: [],
            irrigation: progFactory.checkIrrigation(i, vm.currentUnit.type),
            configuredSubprograms: i.subprograms.filter(
              (sub) =>
                [
                  sub.sector1,
                  sub.sector2,
                  sub.sector3,
                  sub.sector4,
                  sub.sector5,
                  sub.sector6,
                  sub.sector7,
                  sub.sector8,
                  sub.sector9,
                  sub.sector10,
                ].filter((sec) => sec !== 0).length
            ),
            rowItemDisabled: !i.subprograms.filter(
              (sub) =>
                [
                  sub.sector1,
                  sub.sector2,
                  sub.sector3,
                  sub.sector4,
                  sub.sector5,
                  sub.sector6,
                  sub.sector7,
                  sub.sector8,
                  sub.sector9,
                  sub.sector10,
                ].filter((sec) => sec !== 0).length
            ).length,
            ...i,
          }))
          .sort((a, b) => b.configuredSubprograms.length - a.configuredSubprograms.length);
        filterByName();
      });
    }

    function getColDef() {
      vm.gridColumnsDef = [
        { headerName: $filter('translate')('programs.prog3'), field: 'pkId', width: 100 },
        {
          headerName: $filter('translate')('programs.detail.name'),
          field: 'name',
          cellTemplate: (value) => getCellTemplate(value, 'name'),
          filterParams: (cellValue) => cellValue.toLowerCase().includes(vm.searchByProgramName.toLowerCase()),
        },
        {
          headerName: $filter('translate')('programs.edit.start'),
          field: 'editStart',
          cellTemplate: (value) => getEditStartCellTemplate(value),
        },
        {
          headerName: $filter('translate')('history.days') + ' / ' + $filter('translate')('others.activations'),
          field: 'days',
          cellTemplate: (value) => getCellTemplate(value, 'days'),
        },
        {
          headerName: $filter('translate')('programs.detail.startirrig'),
          field: 'startTime',
          width: 150,
          cellTemplate: (value) => getIrrigationStartCellTemplate(value),
        },
        {
          headerName: $filter('translate')('programs.config.subprograms'),
          field: 'subprograms',
          cellTemplate: (value) => getSubprogramsTemplate(value),
        },
        {
          headerName: $filter('translate')('fert.state'),
          field: 'state',
          cellTemplate: (value) => getStateTemplate(value),
        },
      ];
    }
    function changeState(params, config) {
      vm.program = params.program;
      vm.active = null;
      vm.program.op = true;
      if (
        $state.includes('programs.edit') ||
        $state.includes('programs.detail') ||
        $state.includes('programs.config')
      ) {
        $state.go('.', params);
      } else if (config) {
        $state.go('programs.config', params);
      } else {
        $state.go('programs.detail', params);
      }
    }

    function newProgram() {
      var model;
      progFactory.getModel(vm.currentUnit.type).then((item) => {
        model = item;
        prepareModel(model);
        vm.program = model;
        $state.go('programs.edit', { unit: vm.currentUnit, program: vm.program, id: vm.program.pk.id });
      });
    }

    function prepareModel(model) {
      var actives = _.map(vm.programList, (o) => {
        return Number(o.pk.id);
      });
      var comparator = _.range(1, 36);
      var result = _.xor(actives, comparator);
      var newProgram;

      newProgram = _.min(result);

      model.new = true;
      model.selectorNumber = result;
      model.pk.id = newProgram;
      model.pk.deviceId = vm.currentUnit.id;
      model.name = 'Programa ' + newProgram;
      _.forEach(model.subprograms, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
      _.forEach(model.conditioners, (item) => {
        item.pk.programId = newProgram;
        item.pk.deviceId = vm.currentUnit.id;
      });
    }

    /*
     * Funcions per parsejar valors de columna "calculats"
     */
    function getProgramStartType(type) {
      switch (type) {
        case startTypes.hour:
          return 'programs.edit.days';
        case startTypes.seq:
          return 'programs.edit.freq';
        case startTypes.cond:
          if (vm.currentUnit.inoptions.plus) {
            return 'programs.cond';
          } else {
            return 'others.activations';
          }
        default:
          return 'others.activations';
      }
    }

    function getProgramDaysFreq(program) {
      //Inici per freqüencia
      let each = $filter('translate')('programs.edit.each');
      let days_string = $filter('translate')('programs.edit._days');
      return each + ' ' + program.freqDays + ' ' + days_string;
    }

    function getProgramStartTime(prog) {
      switch (prog.type) {
        case startTypes.hour:
          return progFactory.getTimeFromMins(prog.start).format('HH:mm');
        case startTypes.seq:
          return 'P' + prog.start;
        case startTypes.cond:
          return '-';
      }
    }

    function getProgramValueInUnit(prog) {
      var unit = prog.unit;
      var input = prog.value;
      if (unit === 0 || unit === 16) {
        input = input / 60;
      }
      switch (unit) {
        case unitTypes.HM:
          return $filter('parsetime')(input * 60);
        case unitTypes.M3:
          return (input / 1000).toFixed(2) + 'm3';
        case unitTypes.M3HA:
          return (input / 1000).toFixed(2) + ' m3';
        case unitTypes.MS:
          var mins = input;
          return $filter('parsetime', true)(mins);
        case unitTypes.HMHA:
          return input + ' l';
      }
    }

    function changeProgramState(state, _params) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        const modalHeader = vm.currentState.includes('programs.edit')
          ? $filter('translate')('programs.prog_edit')
          : $filter('translate')('programs.prog_config');

        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: modalHeader,
            content: $filter('translate')('programs.edit.cancelq'),
            cancelText: $filter('translate')('AUTOGENERATED.ACTIONS.ACT0003_CANCEL'),
            confirmText: $filter('translate')('units.not_migrated.actions.accept'),
          })
          .then((isConfirmed) => {
            if (isConfirmed) {
              $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
              vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
              $state.go(state, _params); //Canviem d'estat
            }
          });
      } else {
        $state.go(state, _params);
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.formProgram && vm.formProgram.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.formProgram.$setPristine(); //Actualitzem estat del formulari a inicial
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
            $state.go('programs', { program: vm.program, unit: vm.currentUnit });
          } else {
            $state.go('programs.detail', { program: vm.program, id: vm.program.pk.id, unit: vm.currentUnit });
          }
        });
      } else {
        typeof $event !== 'undefined'
          ? $state.go('programs', { program: vm.program, unit: vm.currentUnit })
          : $state.go('programs.detail', { program: vm.program, id: vm.program.pk.id, unit: vm.currentUnit });
      }
    }

    function filterByName() {
      if (vm.searchByProgramName) {
        vm.filterList = vm.programList.filter((program) =>
          program.name.toLowerCase().includes(vm.searchByProgramName.toLowerCase())
        );
      } else {
        vm.filterList = vm.programList;
      }
    }

    function save() {
      $scope.$broadcast('formSubmit');
    }

    // $scope.$on("refresh", function (event, args) {
    //   if ($state.includes("programs.detail")) {
    //     if ($state.params.program !== undefined) {
    //       var program = _.filter(vm.programsList, { name: $state.params.program.name });
    //       program.op = true;
    //       if (program !== undefined && program.length > 0) {
    //         $state.go("programs.detail", { program: program[0], id: program[0].pk.id, unit: $state.params.unit });
    //       } else {
    //         $state.go("programs", { unit: $state.params.unit }, { reload: true });
    //       }
    //       $scope.$emit("refreshed", { message: "refreshed" });
    //     }
    //   } else {
    //     $scope.$emit("refreshed", { message: "refreshed" });
    //   }

    //   loadPrograms($state.params.unit.id);
    // });

    $scope.$on('refresh', () => vm.programsFacade.getA4000Programs(vm.currentUnit.id));

    $scope.$on('updateEdit', function () {
      if ($state.includes('programs.edit') || $state.includes('programs.config')) {
        if ($state.params.program !== undefined && $state.params.program.op) {
          var program = _.filter(vm.programsList, { name: $state.params.program.name });
          if (program !== undefined && program.length > 0) {
            vm.program = program[0];
            vm.program.op = true;
            $state.go('programs.detail', { program: program[0], id: program[0].pk.id, unit: $state.params.unit });
          } else {
            $state.go('programs', { unit: $state.params.unit }, { reload: true });
          }
          $scope.$emit('refreshed', { message: 'refreshed' });
        }
      } else {
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
      loadPrograms($state.params.unit.id);
    });
    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.programsFacade.clearProgramsResponse();
    });
    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.formProgram = args;
    });
  }

  ModalInstanceCtrl.$inject = ['$uibModalInstance', 'program'];

  function ModalInstanceCtrl($uibModalInstance, program) {
    var vm = this;
    vm.program = program;

    vm.ok = function () {
      $uibModalInstance.close(vm.selectedProg);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
