import moment from 'moment/moment';
import Highcharts from 'highcharts/highcharts';
import manual7Sectorpopup from './view/manual-popup.html';
import { UNITS } from '../utils/units.enum';
import { A4500_DEFAULT_CONFIG, getDeviceConfig } from '../utils/device-config';

const colors = require('../../resources/vegga.tokens.colors.json');

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .constant('moment', moment)
    .controller('sectorsDetailController', sectorsDetailController)
    .controller('ModalInstanceCtrlSectors7', ModalInstanceCtrlSectors7);

  sectorsDetailController.$inject = [
    '$log',
    '$scope',
    '$state',
    'progFactory',
    'unitFactory',
    'UserData',
    'graphicsFactory',
    'sectorFactory',
    '$filter',
    'fertilizerFactory',
    'manualFactory',
    '$confirm',
    'calculatedsFactory',
    '$uibModal',
    '$timeout',
    '$translate',
    'sensorsFactory',
    '$rootScope',
  ];

  function sectorsDetailController(
    $log,
    $scope,
    $state,
    progFactory,
    unitFactory,
    UserData,
    graphicsFactory,
    sectorFactory,
    $filter,
    fertilizerFactory,
    manualFactory,
    $confirm,
    calculatedsFactory,
    $uibModal,
    $timeout,
    $translate,
    sensorsFactory,
    $rootScope
  ) {
    var vm = this;
    vm.activeList;
    vm.getSubprogramStartTime;
    vm.getValueInUnit;
    vm.filter;
    vm.dateView;
    vm.isHistoryEmpty;
    vm.UNITS = UNITS;
    vm.activeSectors;
    var sectorPrograms;
    var myChart;
    var storageId;

    var waterDisp;
    let flowUnits;
    let flowUnits45;
    var dataSeries = [];
    var vis;
    var cropTexts;
    let progId;
    let deviceId;
    let params;
    let value;
    let mins;
    let params_sector;
    activate();

    function activate() {
      vm.currentState = $state.includes;
      if ($state.params.sector !== null && $state.params.unit !== null) {
        vm.currentUnit = $state.params.unit;
        if (vm.currentUnit.type === UNITS.A_4500) {
          vm.sector = sectorFactory.flowUnits4500($state.params.sector, vm.currentUnit.installer);
        } else {
          vm.sector = $state.params.sector;
        }
        vm.getCurrentFertilizer = getCurrentFertilizer;
        storageId = vm.currentUnit.id + '-' + UserData.id;
        vm.activeSectors = $scope.$parent.vm.activeList;
        vm.profesional = UserData.profesional;
        vm.filter = '0';

        flowUnits = {
          0: 'm3/h',
          1: 'L/h',
          2: 'L/s',
        };

        flowUnits45 = {
          0: [0, 'm3/h'],
          1: [1, 'm3/h'],
          2: [2, 'm3/h'],
          3: [0, 'L/h'],
          4: [1, 'L/h'],
          5: [2, 'L/h'],
          6: [0, 'L/s'],
          7: [1, 'L/s'],
          8: [2, 'L/s'],
          9: [0, 'm3/s'],
          10: [1, 'm3/s'],
          11: [2, 'm3/s'],
        };

        vm.optionSelect = [
          { text: $filter('translate')('sectors.c34'), value: 0 },
          { text: $filter('translate')('sectors.c35'), value: 1 },
        ];

        vm.parseTimeFromMin = getTimeFromMin;
        vm.changeToProgram = changeToProgram;
        vm.getValueInUnit = getValueInUnit;
        vm.getTimeFromMin = getTimeFromMin;
        vm.getAproxFlow = getAproxFlow;
        vm.getFlowUnit = getFlowUnit;
        vm.keyOrder = keyOrder;
        vm.stop = stop;
        vm.auto = auto;
        vm.start = start;
        vm.initChart = initChart;
        vm.storeChanges = storeChanges;
        vm.updateHistory = updateHistory;
        vm.updateWaterHistory = updateWaterHistory;
        vm.changeDatesGraphics = changeDatesGraphics;

        if (vm.currentUnit.type !== UNITS.A_4500) {
          loadIrrigationProgram();
        }

        if (checkIrrigation()) {
          loadFlowAndMeter();
          vm.sector.plannedFlowF = formatFlowUnit(vm.sector.plannedFlow);
        }

        loadProgramsSector();
        if (vm.currentUnit.type !== 5 && vm.currentUnit.type !== UNITS.A_4500) {
          vm.fertilizers = [];
          loadFertilizerParameters();
        } else {
          vm.openManualPopup = openManualPopup;
        }
        if (vm.sector.crop != null && vm.sector.crop != 0) {
          loadCropText($translate.use());
        }
      } else {
        $state.go('units');
      }
    }

    $scope.$on('VeggaSelectChange', () => {
      updateWaterHistory();
    });

    async function loadCropText(lang) {
      if (cropTexts === undefined) {
        await sectorFactory.cropsText(vm.sector.crop).then((resp) => {
          cropTexts = resp.plain();
        });
      }

      if (vm.sector.crop != undefined && cropTexts.length) {
        switch (lang) {
          case 'es':
            vm.sector.cropLang = cropTexts[0]['name'];
            break;
          case 'it':
            vm.sector.cropLang = cropTexts[3]['name'];
            break;

          case 'en':
            vm.sector.cropLang = cropTexts[1]['name'];
            break;
          case 'fr':
            vm.sector.cropLang = cropTexts[2]['name'];
            break;
          case 'pt':
            vm.sector.cropLang = cropTexts[4]['name'];
            break;
          default:
            vm.sector.cropLang = cropTexts[0]['name'];
        }
      }
    }

    function getFormatMeterAux45() {
      vm.flowUnit = getDeviceConfig(vm.currentUnit)
        .formats.metersFlow.volume.find((format) => format.deviceFormatKey === vm.meter?.flowFormat)
        ?.format?.split(' ')[1];
    }

    function stop() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type == UNITS.A_4500) {
          params.parameter1 = Number(vm.sector.pk.id);
          params.action = 18;
        } else {
          params.parameter1 = Number(vm.sector.pk.id) - 1;
          params.action = 8;
        }
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});
        modalInstance.result.then(() => {
          //                  $state.go('sectors',{unit:$state.params.unit},{reload:true});
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function start() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type == UNITS.A_4500) {
          params.parameter1 = Number(vm.sector.pk.id);
          params.action = 19;
        } else {
          params.parameter1 = Number(vm.sector.pk.id) - 1;
          params.action = 9;
        }

        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});
        modalInstance.result.then(() => {
          //                  $state.go('sectors',{unit:$state.params.unit},{reload:true});
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function auto() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        if (vm.currentUnit.type == UNITS.A_4500) {
          params.parameter1 = Number(vm.sector.pk.id);
          params.action = 20;
        } else {
          params.parameter1 = Number(vm.sector.pk.id) - 1;
          params.action = 10;
        }
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});
        modalInstance.result.then(() => {
          //                  $state.go('sectors',{unit:$state.params.unit},{reload:true});
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function loadHistory(days) {
      params = { from: '', to: '' };
      var dates;
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');

      switch (vm.currentUnit.type) {
        case vm.UNITS.A_4000:
        case vm.UNITS.A_2500:
        case vm.UNITS.A_BIT:
        case vm.UNITS.A_7000:
        case vm.UNITS.A_5500:
          //prepareHistoryLabels();
          vm.historyConfig = sectorFactory.getFilterStorage(storageId);
          if (vm.historyConfig === null) {
            vm.historyConfig = {
              prec: true,
              water: true,
              waterSensor: 0,
            };
          }

          if (vm.sector.waterDispUser === null || vm.sector.waterDisp === null) {
            vm.waterDisabled = true;
            vm.historyConfig.water = false;
          }

          vm.loadinghistory = true;
          params.type = vm.currentUnit.type;
          sectorFactory.precipitation(vm.currentUnit.id, vm.sector.pk.id, params).then(function (data) {
            dates = data.plain();
            //check if historic data is empty
            vm.loadinghistory = false;
            addEmptyDays(dates);
            populateHistoryBar25(dates);
          });

          if (vm.sector.effectiveRainUser !== null && vm.sector.effectiveRain !== null) {
            //Load rain
            loadEffectiveRainHistory(days, vm.sector.effectiveRainUser, vm.sector.effectiveRain);
          }
          if (vm.sector.idUserEto !== null && vm.sector.idEto !== null && vm.sector.idUserCrop !== null) {
            //Validar que el sector contiene ETc
            loadEtcHistory(days);
          }
          if (vm.historyConfig.water) {
            loadWaterDispAndHistory(days);
          }

          break;
        case vm.UNITS.A_4500:
          //prepareHistoryLabels();
          vm.historyConfig = sectorFactory.getFilterStorage(storageId);
          if (vm.historyConfig === null) {
            vm.historyConfig = {
              prec: true,
              water: true,
              waterSensor: 0,
            };
          }

          if (vm.sector.idKUserAvailableWater === null || vm.sector.idAvailableWater === null) {
            vm.waterDisabled = true;
            vm.historyConfig.water = false;
          }

          vm.loadinghistory = true;
          params.type = vm.currentUnit.type;
          sectorFactory.precipitation(vm.currentUnit.id, vm.sector.pk.id, params).then(function (data) {
            dates = data.plain();
            //check if historic data is empty
            vm.loadinghistory = false;
            addEmptyDays(dates);
            populateHistoryBar25(dates);
          });

          if (vm.sector.idUserEffectiveRain !== null && vm.sector.idEffectiveRain !== null) {
            //Load rain
            loadEffectiveRainHistory(days, vm.sector.idUserEffectiveRain, vm.sector.idEffectiveRain);
          }
          if (vm.sector.idUserEto !== null && vm.sector.idEto !== null && vm.sector.idUserCrop !== null) {
            //Validar que el sector contiene ETc
            loadEtcHistory(days);
          }
          if (
            vm.historyConfig.water &&
            vm.sector.idAvailableWater !== null &&
            vm.sector.idKUserAvailableWater !== null
          ) {
            loadWaterDispAndHistory(days);
          }

          break;
      }
    }

    function checkGraphIsEmpty() {
      vm.isHistoryEmpty = !myChart.series.filter((serie) => serie.data.filter((data) => data[1] !== 0).length).length;
    }

    function addEmptyDays(dates) {
      if (dates.length < 7 && dates.length > 0) {
        let labels = [];
        labels.push(moment().subtract(6, 'days').format('YYYY-MM-DD'));
        labels.push(moment().subtract(5, 'days').format('YYYY-MM-DD'));
        labels.push(moment().subtract(4, 'days').format('YYYY-MM-DD'));
        labels.push(moment().subtract(3, 'days').format('YYYY-MM-DD'));
        labels.push(moment().subtract(2, 'days').format('YYYY-MM-DD'));
        labels.push(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        labels.push(moment().format('YYYY-MM-DD'));
        for (let i = 0; i < 7; i++) {
          if ((dates[i] && dates[i][2] && moment(dates[i][2]).format('YYYY-MM-DD') !== labels[i]) || !dates[i]) {
            dates.unshift([0, 0, labels[i]]);
          }
        }
      }
      return dates;
    }

    function loadEtcHistory(days) {
      params = { from: '', to: '' };
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');

      params.group = 1;
      graphicsFactory.getSectorETC(UserData.id, vm.sector.pk.deviceId, vm.sector.pk.id, params).then((result) => {
        populateHistoryETC(result.plain());
      });
    }

    function loadEffectiveRainHistory(days, effectiveRainUser, effectiveRain) {
      params = { from: '', to: '', group: '' };
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');

      //params.sector = vm.sector.pk.id;

      calculatedsFactory.effectiveRainById(effectiveRainUser, effectiveRain).then((res) => {
        if (!res) {
          checkGraphIsEmpty();
          return;
        }
        var ef = res.plain();
        params.group = 2;
        const deviceId = vm.sector.pk.deviceId ?? vm.sector.deviceId;
        graphicsFactory.getMeterConsumption(effectiveRainUser, deviceId, ef.sensorId, params).then((result) => {
          populateHistoryBarRain25(result.plain(), ef);
        });
      });

      /* graphicsFactory.getMeterConsumption(vm.sector.effectiveRainUser, vm.sector.deviceId, 2, params).then(result => {
          calculatedsFactory.effectiveRainHistory(vm.sector.effectiveRainUser,vm.sector.effectiveRain,params).then(result => {*/
    }

    function loadWaterDispAndHistory(days) {
      params = { from: '', to: '' };
      if (days == 365) params.group = 2;
      params.to = moment().add(1, 'days').format('DD-MM-YYYY');
      params.from = moment().subtract(days, 'days').format('DD-MM-YYYY');

      /* myChart.options.scales.yAxes[1].ticks.max = (stored_slider !== null)?stored_slider.max:140;
      myChart.options.scales.yAxes[1].ticks.min = (stored_slider !== null)?stored_slider.min:0;
      myChart.options.scales.yAxes[1].display = true; */
      const waterDispId = vm.currentUnit.type === vm.UNITS.A_4500 ? vm.sector.idAvailableWater : vm.sector.waterDisp;
      const waterDispuserId =
        vm.currentUnit.type === vm.UNITS.A_4500 ? vm.sector.idKUserAvailableWater : vm.sector.waterDispUser;

      switch (vm.historyConfig.waterSensor) {
        case 0:
          calculatedsFactory.getOneWaterDisp(waterDispuserId, waterDispId).then((data) => {
            if (!data) {
              checkGraphIsEmpty();
              return;
            }
            waterDisp = data.plain();
            calculatedsFactory.waterHistory(waterDispuserId, waterDispId, 0, params).then((data) => {
              prepareHistoryLines();
              populateHistoryLines(data.plain(), null, waterDisp);
            });
          });
          break;
        case 1:
          calculatedsFactory.getOneWaterDisp(waterDispuserId, waterDispId).then((data) => {
            if (!data) {
              checkGraphIsEmpty();
              return;
            }
            waterDisp = data.plain();
            prepareHistoryLines();
            _.forEach(waterDisp.sensors, (s, k) => {
              calculatedsFactory.waterHistory(waterDispuserId, waterDispId, k + 1, params).then((data) => {
                populateHistoryLines(data.plain(), s, waterDisp);
              });
            });
          });
          break;
      }
      /*      */
    }

    function populateHistoryBar25(dates) {
      let data = [];
      if (dates.length > 0) {
        var date_timestamp;
        dates = _.orderBy(dates, (o) => {
          return moment(o[2], 'YYYY-MM-DD HH:mm');
        });
        _.forEach(dates, (o) => {
          date_timestamp = moment(o[2], 'YYYY-MM-DD');
          date_timestamp = date_timestamp.format('x');
          date_timestamp = parseInt(date_timestamp);
          data.push([date_timestamp, o[1] ?? 0]);
        });
      }

      vis = visibleSerie('Riego');
      myChart.addSeries({
        name: 'Riego',
        stack: 0,
        stacking: 'normal',
        visible: vis,
        type: 'column',
        color: '#47C5DF',
        yAxis: 'irrig',
        data: data,
      });
      checkGraphIsEmpty();
    }

    function populateHistoryETC(dates) {
      let data = [];
      if (dates.length > 0) {
        var date_timestamp;
        _.forEach(dates, (o) => {
          date_timestamp = moment(o.date, 'DD-MM-YYYY').format('x');
          date_timestamp = parseInt(date_timestamp);
          data.push([date_timestamp, o.value]);
        });
      }

      vis = visibleSerie('ETc');
      myChart.addSeries({
        name: 'ETc',
        type: 'column',
        visible: vis,
        color: '#FFC72C',
        yAxis: 'irrig',
        data: data,
      });
      checkGraphIsEmpty();
    }

    function populateHistoryBarRain25(dates, ef) {
      let data = [];
      if (dates.length > 0) {
        var date_timestamp;
        _.forEach(dates, (o) => {
          date_timestamp = moment(o.date, 'DD-MM-YYYY').format('x');
          date_timestamp = parseInt(date_timestamp);
          if (ef) {
            if (ef.percentage > 0 && ef.min != null) {
              o.value = (o.value - ef.min) * ef.percentage;
            } else if (ef.min > 0) {
              o.value = o.value - ef.min;
            }
            if (o.value < 0) o.value = 0;
          }
          data.push([date_timestamp, o.value]);
        });
      }
      vis = visibleSerie('Lluvia');
      myChart.addSeries({
        name: 'Lluvia',
        stack: 0,
        stacking: 'normal',
        type: 'column',
        visible: vis,
        color: '#00A5BB',
        yAxis: 'irrig',
        data: data,
      });
      checkGraphIsEmpty();
    }

    function prepareHistoryLines() {
      var id_store = vm.sector.waterDispUser + '-' + vm.sector.waterDisp;
      if (id_store === undefined) {
        id_store = UserData.id + '-' + vm.sector.waterDisp;
      }
      var stored_slider = calculatedsFactory.getFilterStorage(id_store);
      myChart.addAxis({
        // Secondary yAxis
        id: 'water',
        showEmpty: false,
        labels: {
          formatter: function () {
            return this.value + ' %';
          },
        },
        offset: 15,
        max: stored_slider !== null ? stored_slider.max : 140,
        min: stored_slider !== null ? stored_slider.min : 0,
        opposite: true,
        lineWidth: 2,
        title: {
          text: '',
        },
      });
    }

    function populateHistoryLines(dates, sensor, wd) {
      var colors = ['#0e3f39', '#0e3f39', '#a3efe2', '#e6e600'];
      if (dates.length > 0) {
        var data = [];
        var date_timestamp;
        _.forEach(dates, (o) => {
          date_timestamp = moment(o[1], 'DD-MM-YYYY HH:mm').format('x');
          date_timestamp = parseInt(date_timestamp);
          data.push([date_timestamp, o[0]]);
        });
        if (wd !== null && wd !== undefined) {
          waterDispBg(wd);
        }
      }
      //vis = visibleSerie(((sensor!==null)?sensor.name:'Agua disponible'));
      myChart.addSeries({
        name: sensor !== null ? sensor.name : 'Agua disponible',
        type: 'line',
        visible: 1,
        color: sensor !== null ? colors[sensor.pk.id] : '#0e3f39',
        yAxis: 'water',
        data: data,
      });
      checkGraphIsEmpty();
    }

    function waterDispBg(waterDisp) {
      const dispWater = waterDisp.cicles[0];
      var plotbands = [
        {
          from: 100,
          to: 150,
          borderColor: 'rgb(0,187,222,0.01)',
          borderWidth: 1,
          color: '#DAF1F5',
        },
        {
          from: dispWater.recargaPoint,
          to: 100,
          borderColor: 'rgb(0,169,159,0.01)',
          borderWidth: 1,
          color: '#BEE2E0',
        },
        {
          from: dispWater.estresPoint,
          to: dispWater.recargaPoint,
          borderColor: 'rgb(255,146,100,0.01)',
          borderWidth: 1,
          color: '#FFEEE6',
        },
        {
          from: 0,
          to: dispWater.estresPoint,
          borderColor: 'rgb(255,72,75,0.01)',
          borderWidth: 1,
          color: '#FFE7E6',
        },
      ];
      var plotlines = [];
      if (dispWater.line1 !== null) {
        plotlines.push({
          id: 'hline',
          value: dispWater.line1,
          color: 'black',
          width: 2,
          dashStyle: 'dot',
          label: {
            align: 'right',
            useHTML: true,
            text: "<h5 style='background-color: #FFC72C'>M1</h5>",
          },
        });
      }

      if (dispWater.line2 !== null) {
        plotlines.push({
          id: 'hline2',
          value: dispWater.line2,
          color: 'black',
          width: 2,
          dashStyle: 'dot',
          label: {
            align: 'right',
            useHTML: true,
            text: "<h5 style='background-color: #FFC72C'>M2</h5>",
          },
        });
      }
      plotbands.forEach((pb) => myChart.yAxis[1].addPlotBand(pb));
      plotlines.forEach((pl) => myChart.yAxis[1].addPlotLine(pl));
    }

    function visibleSerie(serieName) {
      if (dataSeries.length > 0) {
        vis = _.find(dataSeries, function (o) {
          return o[0] == serieName;
        });
        return (vis = vis[1]);
      } else {
        return (vis = true);
      }
    }

    function updateHistory() {
      let days;
      sectorFactory.setFilterStorage(vm.historyConfig, storageId);
      if (vm.historyConfig.prec) {
        myChart.options.scales.yAxes[0].display = true;
        _.forEach(myChart.data.datasets, (set) => {
          if (set.type !== 'line') {
            set.hidden = false;
            if (_.isEmpty(set.data)) {
              vm.loadinghistory = true;
              sectorFactory.precipitation(vm.currentUnit.id, vm.sector.pk.id, params).then(function (data) {
                vm.loadinghistory = false;
                populateHistoryBar25(data.plain());
              });
            }
          }
        });
      } else {
        myChart.options.scales.yAxes[0].display = false;
        _.forEach(myChart.data.datasets, (set) => {
          if (set.type !== 'line') {
            set.hidden = true;
          }
        });
      }
      if (vm.historyConfig.water) {
        if (vm.filter == '0') days = 7;
        else if (vm.filter == '1') days = 30;
        else if (vm.filter == '2') days = 365;
        myChart.options.scales.yAxes[1].display = true;
        _.forEach(myChart.data.datasets, (set) => {
          if (set.type === 'line') {
            set.hidden = false;
            if (_.isEmpty(set.data)) {
              loadWaterDispAndHistory(days);
            }
          }
        });
      } else {
        myChart.options.scales.yAxes[1].display = false;
        _.forEach(myChart.data.datasets, (set) => {
          if (set.type === 'line') {
            set.hidden = true;
          }
        });
      }
      myChart.update();
    }

    function updateWaterHistory() {
      var days;
      if (vm.filter == '0') days = 7;
      else if (vm.filter == '1') days = 30;
      else if (vm.filter == '2') days = 365;
      sectorFactory.setFilterStorage(vm.historyConfig, storageId);
      myChart.get('water').remove();
      loadWaterDispAndHistory(days);
    }

    function changeToProgram(program) {
      if (program !== undefined) {
        if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
          //A4000
          $state.go('programs.detail', { program: program, id: program.pk.id, unit: vm.currentUnit });
        } else if (vm.currentUnit.progtype === vm.UNITS.A_2500.toString()) {
          $state.go('a25programs.detail', { program: program, id: program.pk.id, unit: vm.currentUnit });
        }
      } else {
        if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
          //A2500
          $state.go('programs.detail', { program: vm.program, id: vm.program.pk.id, unit: vm.currentUnit });
        } else if (vm.currentUnit.progtype === vm.UNITS.A_2500.toString()) {
          $state.go('a25programs.detail', { program: vm.program, id: vm.program.pk.id, unit: vm.currentUnit });
        }
      }
    }

    function loadProgramsSector() {
      sectorFactory.programs(vm.currentUnit.id, vm.sector.pk.id).then(function (data) {
        sectorPrograms = data.plain();
        //TODO falta millorar implementació, es deixa a false per mostrar només els programes assignats
        // if (false && vm.currentUnit.type !== 5) {
        //   var actualMonth = moment().format('MM-YYYY');
        //   var eventTable = [];
        //   _.forEach(sectorPrograms, (value) => {
        //     var events = progFactory.getEventTable(value, actualMonth);
        //     if (events && events.length > 0) {
        //       if (events[0]) value.events1 = moment(events[0].startsAt).format('DD-MM');
        //       if (events[1]) {
        //         value.events2 = moment(events[1].startsAt).format('DD-MM');
        //       } else {
        //         value.events2 = moment(events[0].startsAt).add(1, 'day').format('DD-MM');
        //       }
        //       value.startTime = moment(getTimeFromMins(value.start)).format('HH:mm');
        //     }
        //   });

        //   var table = _.concat(
        //     _.map(sectorPrograms, function (value, prop) {
        //       if (value.events1 !== undefined) {
        //         var _order = value.events1.split('-');
        //         _order = _order[1] + _order[0];
        //         return { key: value.events1, value: [value], _order: _order };
        //       }
        //     }).sort(),
        //     _.map(sectorPrograms, function (value, prop) {
        //       if (value.events2 !== undefined) {
        //         var _order = value.events2.split('-');
        //         _order = _order[1] + _order[0];
        //         return { key: value.events2, value: [value], _order: _order };
        //       }
        //     }).sort()
        //   );
        //   var orderedTable = _(table).sortBy('_order').value();

        //   for (var i = 0; i < orderedTable.length; i++) {
        //     var index = i;
        //     if (orderedTable[index] && orderedTable[index + 1]) {
        //       if (orderedTable[index].key !== undefined && orderedTable[index].key === orderedTable[index + 1].key) {
        //         if (_.isArray(orderedTable[index].value)) {
        //           orderedTable[index].value.push(orderedTable[index + 1].value[0]);
        //         } /*else{
        //                         orderedTable[index].value = [orderedTable[index].value,orderedTable[index+1]];
        //                     }*/
        //         orderedTable.splice(index + 1, 1);
        //         i--;
        //       }
        //     }
        //   }

        //   vm.firstGroup = orderedTable;
        // } else {
        vm.sectorPrograms = sectorPrograms;
        // }
      });
    }

    function loadFlowAndMeter() {
      deviceId = vm.currentUnit.id;
      var meterId = vm.sector.irrigationMeter;
      if (meterId !== 0) {
        unitFactory.meter(deviceId, meterId).then(function (data) {
          vm.meter = data;
          switch (vm.currentUnit.type) {
            case 2:
              vm.meter.flow = parseUnitFlow(vm.meter.xFlow);
              break;
            case 3:
            case 4:
            case 5:
            case 6:
              vm.meter.flow = formatFlowUnit(vm.meter.xFlow);
              break;
            case 7:
              vm.meter.flow = formatFlowUnit45(vm.meter.xInstantFlow, vm.sector.flowFormat);
              break;
          }
          if (vm.currentUnit.type === UNITS.A_4500 && vm.sector.detectorOrMeter == 1 && vm.sector.flowInput > 0) {
            getFormatMeterAux45();
          }
        });
      }
    }

    function parseUnitFlow(value) {
      value = '' + value;
      if (vm.currentUnit.installer) {
        var decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function formatFlowUnit45(value, format) {
      let decimals = flowUnits45[format][0];
      let val = String(value);
      var first = val.slice(0, val.length - decimals);
      var last = val.slice(val.length - decimals, val.length);
      val = first + '.' + last;
      return _.toNumber(val);
    }

    function formatFlowUnit(unit) {
      value = String(unit);
      if (value.length === 2) {
        value = '0.' + value;
      } else if (value.length === 1) {
        value = '0.0' + value;
      } else if (value.length > 2) {
        var first = value.slice(0, value.length - 2);
        var last = value.slice(value.length - 2, value.length);
        value = first + '.' + last;
      }
      return Number(value);
    }

    function getCurrentFertilizer(index) {
      return vm.fertilizers[index];
    }

    function loadFertilizerParameters() {
      if (vm.currentUnit.type !== 4 && vm.currentUnit.type !== UNITS.A_4500) {
        fertilizerFactory.all(vm.currentUnit.id).then((fert) => {
          vm.parameters = fert;
          _.times(4, (x) => {
            var currentProgram = vm.parameters.fertilizers[x].xProgramN;
            if (vm.program && currentProgram === Number(vm.program.pk.id)) {
              var key = 'fertilizer' + (x + 1);
              var total = vm.program[key];
              vm.fertilizers[x] = total - vm.parameters.fertilizers[x].xVolume;
            } else {
              vm.fertilizers[x] = -1;
            }
          });
        });
      }
    }

    function loadIrrigationProgram() {
      switch (vm.currentUnit.type) {
        case 2:
          if (vm.sector.xProgramN != 0 && vm.sector.xSubprogramN != 0) {
            deviceId = vm.currentUnit.id;
            progId = vm.sector.xProgramN;
            params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data.plain();
              progFactory.checkIrrigation(vm.program, vm.currentUnit.type);
              loadSectorsFromSubProgram();
            });
          }
          break;
        case 3:
        case 4:
        case 6:
          if (vm.sector.xProgramN != 0) {
            deviceId = vm.currentUnit.id;
            progId = vm.sector.xProgramN;
            params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data.plain();
              progFactory.checkIrrigation(vm.program, vm.currentUnit.type);
              loadSectorsFromProgram();
              if (vm.currentUnit.type === vm.UNITS.A_5500) {
                var subprogram = _.filter(vm.program.programSector, { sector: Number(vm.sector.id) });
                vm.program.value = subprogram[0] !== undefined ? subprogram[0].value : 0;
              }
              if (vm.currentUnit.type === vm.UNITS.A_2500 && vm.program.unit === 2) {
                var sectorsArray = [];
                if (vm.program.sector1 !== null && vm.program.sector1 !== 0) {
                  sectorsArray.push(vm.program.sector1);
                }
                if (vm.program.sector2 !== null && vm.program.sector2 !== 0) {
                  sectorsArray.push(vm.program.sector2);
                }
                if (vm.program.sector3 !== null && vm.program.sector3 !== 0) {
                  sectorsArray.push(vm.program.sector3);
                }
                if (vm.program.sector4 !== null && vm.program.sector4 !== 0) {
                  sectorsArray.push(vm.program.sector4);
                }

                params_sector = {
                  sectors: sectorsArray,
                };
                progFactory.getSectorsArea(vm.currentUnit.id, params_sector).then((result) => {
                  vm.sectorsArea = result;
                  if (vm.sectorsArea > 0 && vm.sectorsArea !== null && vm.sectorsArea !== undefined) {
                    vm.programed = (vm.program.value * (vm.sectorsArea / 10000)).toFixed(2);
                  }
                });
              }
            });
          }
          break;
        case 5:
          if (vm.sector.xProgramN != 0 && vm.sector.xProgramN != 0) {
            deviceId = vm.currentUnit.id;
            progId = vm.sector.xProgramN;
            params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data.plain();
              loadSectorsFromSubProgram7();
            });
          }
          break;
        case 7:
          //TODO al fer merge la consulta tindra subprogram quan és per subprograma el --> && vm.sector.xSubprogramN != 0
          if (vm.sector.xNProgram != 0) {
            deviceId = vm.currentUnit.id;
            progId = vm.sector.xNProgram;
            params = null; //{'subprogram':vm.sector.xSubprogramN}
            progFactory.program(deviceId, progId, params).then(function (data) {
              vm.program = data.plain();
              //progFactory.checkIrrigation(vm.program, vm.currentUnit.type);
              //loadSectorsFromSubProgram();
            });
          }
          break;
      }
    }

    function loadSectorsFromProgram() {
      if (vm.sector.xProgramN != 0 && vm.program) {
        var fertilizers = _.pickBy(vm.program, function (value, key) {
          return _.startsWith(key, 'fertilizer');
        });
        vm.fertilizers = fertilizers;
      }
    }

    function loadSectorsFromSubProgram7() {
      let sub = _.find(vm.program.subprograms, (s) => {
        let id = Number(vm.sector.pk.id);
        return s.sector === id && s.xState === 1;
      });
      vm.program.value = sub.value;
      vm.program.xValue = sub.xValue;
      //vm.plannedFlow = formatFlowUnit(vm.sector.plannedFlow);
    }

    function getAproxFlow() {
      if (vm.meter && vm.meter.flow !== undefined) {
        if (vm.sector.plannedFlowF > 0 && vm.sector.totalFlow > 0) {
          if (vm.currentUnit.type === UNITS.A_4500) {
            const flowFormats = A4500_DEFAULT_CONFIG.formats.metersFlow.volume;
            const meterFormat = flowFormats.find((format) => format.deviceFormatKey === vm.meter.flowFormat);

            if (!meterFormat) {
              value = (vm.meter.flow * vm.sector.plannedFlowF) / vm.sector.totalFlow;
            } else {
              // We get the numeric part 00.00 m3/h => 00.00
              const numericFragment = meterFormat.format.split(' ')[0];
              const decimalFragment = numericFragment.split('.')[1];

              // Math.pow(10, decimalFragment.length) -> 1 decimal = 10 , 2 decimals = 100
              value =
                ((vm.meter.flow / (decimalFragment ? Math.pow(10, decimalFragment.length) : 1)) *
                  vm.sector.plannedFlowF) /
                vm.sector.totalFlow;
            }
          } else {
            value = (vm.meter.flow * vm.sector.plannedFlowF) / vm.sector.totalFlow;
          }
        } else {
          if (vm.currentUnit.type === UNITS.A_4500) {
            const flowFormats = A4500_DEFAULT_CONFIG.formats.metersFlow.volume;
            const meterFormat = flowFormats.find((format) => format.deviceFormatKey === vm.meter.flowFormat);
            const numericFragment = meterFormat.format.split(' ')[0];
            const decimalFragment = numericFragment.split('.')[1];

            value = vm.meter.flow / (decimalFragment ? Math.pow(10, decimalFragment.length) : 1);
          } else {
            value = vm.meter.flow;
          }
        }
      }
      if (value !== undefined && !isNaN(value)) {
        if (vm.currentUnit.type === UNITS.A_4500) {
          return vm.meter?.xInstantFlow
            ? ((+vm.meter.xInstantFlow * vm.sector.plannedFlowF) / vm.sector.totalFlow).toFixed(2)
            : (+value).toFixed(2);
        }
        return (+value).toFixed(2);
      } else {
        return 0;
      }
    }

    function getFlowUnit() {
      if (vm.meter && vm.meter.flowFormat !== undefined) {
        if (vm.currentUnit.type == UNITS.A_4500) {
          return flowUnits45[vm.meter.flowFormat][1];
        } else return flowUnits[vm.meter.flowFormat];
      } else {
        return '';
      }
    }

    function loadSectorsFromSubProgram() {
      if (vm.sector.xProgramN != 0 && vm.sector.xSubprogramN != 0 && vm.program) {
        var subprog = vm.program.subprograms[vm.sector.xSubprogramN - 1];
        //Sector extraction from subprogram
        /*          var sectors =  _.pickBy(subprog, function(value, key) {
                         return _.startsWith(key, "sector");
                       });

                  var sectorArray = _.filter(_.values(sectors),function(val){ return val !== 0});
                  vm.sectorsLength = sectorArray.length;
                  vm.plannedFlow = 0;

                  if(vm.sectorsLength > 1){
                      _.forEach(sectorArray,function(key){
                          var sector = _.find(vm.activeSectors, (o) => { return o.id === String(key)})
                          if(sector) vm.plannedFlow = vm.plannedFlow + sector.plannedFlow;
                      });
                  }else{
                      vm.plannedFlow = vm.sector.plannedFlow;
                  }

                  vm.plannedFlow = formatFlowUnit(vm.plannedFlow);
                  vm.plannedFlow2 = vm.plannedFlow * 2; */
        //Fertilizer extraction from subprogram
        var fertilizers = _.pickBy(subprog, function (value, key) {
          return _.startsWith(key, 'fertilizer');
        });
        //var fertilizerArray = _.filter(_.values(fertilizers),function(val){ return val !== 0});
        vm.fertilizers = fertilizers;
      }
    }

    function checkIrrigation() {
      vm.sector.irrigation = false;
      if (vm.sector.xStatus !== null) {
        switch (vm.currentUnit.type) {
          case 2: //A4000
            if (vm.sector.xStatus !== 4 && vm.sector.xStatus !== 0) {
              // 4 = Stop In active (Paro definitiu de sector)
              vm.sector.irrigation = true;
            }
            break;
          case 3: //A2500
          case 4: //BIT
            if (vm.sector.xStatus !== 0 && vm.sector.xStatus !== 2 && vm.sector.xStatus !== 5) {
              vm.sector.irrigation = true;
            }
            break;
          case 5: //A7000
            if (vm.sector.xStatus !== 0) {
              vm.sector.irrigation = true;
            }
            break;
          case 6: //A5500
            if (
              vm.sector.xStatus !== 0 &&
              vm.sector.xStatus !== 3 &&
              vm.sector.xStatus !== 5 &&
              vm.sector.xStatus !== 6
            ) {
              vm.sector.irrigation = true;
            }
            break;
          case 7: //A4500
            if (vm.sector.xStatus == 1 || vm.sector.xStatus == 4) {
              vm.sector.irrigation = true;
            }
            break;
        }
      }

      return vm.sector.irrigation;
    }

    function getValueInUnit(input) {
      if (vm.program !== undefined && vm.sector !== undefined) {
        var unit;
        switch (vm.currentUnit.type) {
          case 2:
            unit = vm.program.subprograms[vm.sector.xSubprogramN - 1].unit;
            switch (unit) {
              case 0:
                return $filter('parsetime')(input * 60);

              case 1:
                mins = input * 60 * 60;
                return $filter('parsetime')(mins);

              case 2:
                return input + 'm3';

              case 4:
                return input + 'l';

              case 16:
                return input + 'm3/h';
            }

            break;
          case 3:
          case 4:
            unit = vm.program.unit;
            switch (unit) {
              case 0:
                return $filter('parsetime')(input);
              case 3:
                mins = input;
                return $filter('parsetimesec')(mins);
              case 1:
                return (input / 1000).toFixed(2) + 'm3';
              case 4:
                return $filter('parsetime')(input);
              case 2:
                //if(prog == 1) input = vm.programed;
                return (input / 1000).toFixed(2) + 'm3';
            }
            break;
          case 5:
            if (!_.isNumber(input)) {
              input = 0;
            }
            switch (vm.program.units) {
              case 0:
              case 1:
                return $filter('parsetime')(input * 60);

              case 2:
                return input + 'm3';

              case 3:
                return input + 'L';
            }
            break;
          case 6:
            unit = vm.program.unit;
            switch (unit) {
              case 0:
                return $filter('parsetime')(input);

              case 3:
                mins = input;
                return $filter('parsetimesec')(mins);

              case 1:
                return input / 1000 + 'm3';

              case 2:
                return input / 1000 + 'm3/Ha';
              case 4:
                return $filter('parsetime')(input);
            }

            break;
        }
      }
    }

    function getTimeFromMin(min) {
      if (_.isNumber(min)) {
        return progFactory.getTimeFromMins(min).format('HH:mm');
      }
      return min;
    }

    function keyOrder(k) {
      return k;
    }

    function storeChanges() {
      sectorFactory.setFilterStorage(vm.historyConfig, storageId);
    }

    function initChart(days) {
      Highcharts.setOptions({
        lang: {
          shortMonths: [
            $filter('translate')('others.short_months.january'),
            $filter('translate')('others.short_months.february'),
            $filter('translate')('others.short_months.march'),
            $filter('translate')('others.short_months.april'),
            $filter('translate')('others.short_months.may'),
            $filter('translate')('others.short_months.june'),
            $filter('translate')('others.short_months.july'),
            $filter('translate')('others.short_months.august'),
            $filter('translate')('others.short_months.september'),
            $filter('translate')('others.short_months.octuber'),
            $filter('translate')('others.short_months.november'),
            $filter('translate')('others.short_months.december'),
          ],
          weekdays: [
            $filter('translate')('programs.edit.sun'),
            $filter('translate')('programs.edit.mon'),
            $filter('translate')('programs.edit.tue'),
            $filter('translate')('programs.edit.wed'),
            $filter('translate')('programs.edit.thu'),
            $filter('translate')('programs.edit.fri'),
            $filter('translate')('programs.edit.sat'),
          ],
        },
      });

      myChart = new Highcharts.Chart({
        time: {
          useUTC: false,
        },
        title: {
          enabled: false,
          text: '',
        },
        tooltip: {
          shared: true,
        },
        chart: {
          alignTicks: false,
          zoomType: 'x',
          renderTo: 'hidroStatusChart',
          type: 'line',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%d-%m-%Y}',
            rotation: -65,
          },
          lineColor: colors['vegga-color-secondary-default'],
        },
        yAxis: {
          id: 'irrig',
          showEmpty: false,
          endOnTick: false,
          labels: {
            formatter: function () {
              return this.value + ' mm';
            },
          },
          lineWidth: 2,
          title: {
            text: '',
          },
          offset: 15,
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: colors['vegga-color-secondary-default'],
            },
          },
        },
        plotOptions: {
          column: {
            pointPlacement: 'between',
            dataLabels: {
              enabled: false,
            },
          },
        },
        exporting: {
          enabled: false,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'separator', 'downloadCSV', 'downloadXLS'],
            },
          },
        },
      });

      if (days == 365) {
        myChart.yAxis[0].options.stackLabels.enabled = false;
      }

      loadHistory(days);
      updateChartWidth(myChart);
    }

    function openManualPopup() {
      var modalInstance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        template: manual7Sectorpopup,
        controller: 'ModalInstanceCtrlSectors7',
        controllerAs: 'vm',
        resolve: {
          program: function () {
            return vm.sector;
          },
          unit: function () {
            return vm.currentUnit;
          },
        },
      });

      modalInstance.result.then(
        function () {
          var modalInstance = manualFactory.showLoadingBar();

          modalInstance.result.then(() => {
            $rootScope.$broadcast('reload', { message: 'refreshing' });
          });
        },
        function () {
          $log.info('Modal dismissed at: ' + new Date());
        }
      );
    }

    $scope.$on('refresh:sector', function (_event, args) {
      vm.sector = args.sector;
      vm.currentUnit = args.unit;
      if (vm.currentUnit.type === UNITS.A_4500 && vm.sector.detectorOrMeter == 1 && vm.sector.flowInput > 0) {
        getFormatMeterAux45();
      }
      vm.activeSectors = $scope.$parent.vm.activeList;
      loadIrrigationProgram();

      if (checkIrrigation()) {
        loadFlowAndMeter();
        vm.sector.plannedFlowF = formatFlowUnit(vm.sector.plannedFlow);
      }

      loadProgramsSector();
      vm.fertilizers = [];
      loadFertilizerParameters();
      vm.parseTimeFromMin = getTimeFromMin;
      params = {};
      params.type = vm.currentUnit.type;
      params.deviceId = vm.currentUnit.id;
      params.parameter1 = Number(vm.sector.pk.id) - 1;
    });

    function changeDatesGraphics($event) {
      vm.filter = $event.detail.value;
      const days = vm.filter === '0' ? 7 : vm.filter === '1' ? 30 : 365;
      dataSeries = [];
      _.forEach(myChart.series, (o) => {
        dataSeries.push([o.name, o.visible]);
      });
      myChart.destroy();
      initChart(days);
    }

    $scope.$on('lang-update', (ev, args) => {
      loadCropText(args.lang);
    });

    $(window).resize(() => updateChartWidth());
  }

  function updateChartWidth(chart) {
    chart = chart || $('#hidroStatusChart').highcharts();
    if (chart) {
      const overlayContentEl = document.querySelector('vegga-overlay-content');
      const chartOffset = document.body.clientWidth < 576 ? 100 : 200;
      chart.setSize(overlayContentEl.offsetWidth - chartOffset, null, true);
    }
  }

  ModalInstanceCtrlSectors7.$inject = ['$uibModalInstance', 'program', 'unit', '$confirm', 'manualFactory', '$filter'];

  function ModalInstanceCtrlSectors7($uibModalInstance, sector, _unit, $confirm, manualFactory, $filter) {
    var vm = this;
    vm.sector = sector;
    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    vm.manualChangeCE = manualChangeCE;
    vm.manualChangePH = manualChangePH;
    let params;

    function manualChangeCE() {
      $uibModalInstance.dismiss('cancel');
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        params = {};
        params.type = 5;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 21;
        params.parameter1 = Number(vm.sector.pk.id) - 1;
        params.parameter2 = Number(vm.sector.ceAd);
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          //$rootScope.$broadcast('updateEdit7', { message: 'refreshing' });;
        });
      });
    }

    function manualChangePH() {
      $uibModalInstance.dismiss('cancel');
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        params = {};
        params.type = 5;
        params.deviceId = vm.program.pk.deviceId;
        params.action = 22;
        params.parameter1 = Number(vm.sector.pk.id) - 1;
        params.parameter2 = Number(vm.sector.phAd);
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        var modalInstance = manualFactory.showLoadingBar();
        modalInstance.result.then(() => {
          //$rootScope.$broadcast('updateEdit7', { message: 'refreshing' });
        });
      });
    }
  }
})();
